var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-4 px-4"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":_vm.newZone}},'v-btn',attrs,false),on),[_vm._v(" Agregar zona ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sumbit($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Zone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nombre de zona","required":"","dense":"","placeholder":"Zona","outlined":""},model:{value:(_vm.postData.name),callback:function ($$v) {_vm.$set(_vm.postData, "name", $$v)},expression:"postData.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Seleccionar ciudad","persistent-hint":"","items":_vm.cities.cities,"item-text":"name","item-value":"zbCityId","outlined":"","dense":""},model:{value:(_vm.postData.cityId),callback:function ($$v) {_vm.$set(_vm.postData, "cityId", $$v)},expression:"postData.cityId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":"image/*","label":"Imágen de zona versión 1","placeholder":"Imágen versión 1","outlined":"","dense":""},on:{"change":function($event){return _vm.changeImage($event, 'v1')}},model:{value:(_vm.itemImage),callback:function ($$v) {_vm.itemImage=$$v},expression:"itemImage"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.imageV1,"max-height":"200","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"ImageV2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":"image/*","label":"Imágen de zona versión 2","placeholder":"Imágen version 2","outlined":"","dense":""},on:{"change":function($event){return _vm.changeImage($event, 'v2')}},model:{value:(_vm.itemImageV2),callback:function ($$v) {_vm.itemImageV2=$$v},expression:"itemImageV2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.imageV2,"max-height":"200","contain":""}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelButton}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }