import { ServiceFactory } from '@/services/ServiceFactory'
const _zonesService = ServiceFactory.get('ZonesService')
export default {
  data  () {
    return {
      dialog: false,
      postData: {
        cityId: '',
        name: ''
      },
      itemImage: null,
      itemImageV2: null,
      menu: false,
      menu2: false,
      loading: false,
      action: 'create',
      title: 'Agregar zona',
      imageV1: '',
      imageV2: ''
    }
  },
  props: {
    cities: Object,
    zone: Object
  },
  watch: {
    zone: {
      handler: 'setItem',
      immediate: true
    }
  },
  methods: {
    async setItem () {
      if (this.zone) {
        this.postData = this.zone
        this.dialog = true
        console.log(this.zone)
        this.action = 'edit'
        this.title = 'Editar zona'
        this.imageV1 = this.zone.image
        this.imageV2 = this.zone.imageV2
      } else {
        this.action = 'create'
        this.title = 'Agregar zona'
        this.imageV1 = null
        this.imageV2 = null
      }
      // this.dates = [this.postData.dateFrom, this.postData.dateTo]
      // try {
      //   this.companies = await _barsService.listBar()
      // } catch (error) {
      //   console.log(error)
      // }
    },
    newZone () {
      this.title = 'Agregar zona'
      this.imageV1 = ''
      this.imageV2 = ''
    },
    cancelButton () {
      this.dialog = false
      this.postData = {
        cityId: '',
        name: ''
      }
      this.itemImage = null
      this.itemImageV2 = null
      this.imageV1 = ''
      this.imageV1 = ''
    },
    async submitCreate () {
      this.loading = true
      console.log(this.postData)
      console.log(this.cities)
      console.log(this.itemImage)
      console.log(this.itemImageV2)
      try {
        const newItem = await _zonesService.create(this.postData)
        console.log(newItem)
        if (this.itemImage) {
          const newImage = new FormData()
          newImage.append('image', this.itemImage)
          const addImg = await _zonesService.image(newItem.zones.id, newImage, 'image')
          console.log(addImg)
          console.log('imagen agregada')
        }
        if (this.itemImageV2) {
          const newImageV2 = new FormData()
          newImageV2.append('image', this.itemImageV2)
          const addImg = await _zonesService.image(newItem.zones.id, newImageV2, 'image_v2')
          console.log(addImg)
          console.log('imagen agregada')
        }
        this.dialog = false
        this.$notify({
          type: 'success',
          text: 'Zona registrada correctamente'
        })
        this.loading = false
        this.postData = {
          cityId: '',
          name: ''
        }
        this.itemImage = null
        this.itemImageV2 = null
        this.$emit('updateTable')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.dialog = false
        this.loading = false
      }
    },
    async submitEdit () {
      this.loading = true
      console.log(this.postData)
      console.log(this.cities)
      console.log(this.itemImage)
      console.log(this.itemImageV2)
      try {
        const editItem = await _zonesService.edit(this.postData.id, this.postData)
        console.log(editItem)
        if (this.itemImage) {
          const newImage = new FormData()
          newImage.append('image', this.itemImage)
          const addImg = await _zonesService.image(this.postData.id, newImage, 'image')
          console.log(addImg)
          console.log('imagen agregada')
        }
        if (this.itemImageV2) {
          const newImageV2 = new FormData()
          newImageV2.append('image', this.itemImageV2)
          const addImg = await _zonesService.image(this.postData.id, newImageV2, 'image_v2')
          console.log(addImg)
          console.log('imagen agregada')
        }
        this.dialog = false
        this.$notify({
          type: 'success',
          text: 'Zona editada correctamente'
        })
        this.loading = false
        this.postData = {
          cityId: '',
          name: ''
        }
        this.itemImage = null
        this.itemImageV2 = null
        this.$emit('updateTable')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.dialog = false
        this.loading = false
      }
    },
    async sumbit () {
      if (this.action === 'create') {
        this.submitCreate()
      } else if (this.action === 'edit') {
        this.submitEdit()
      }
    },
    changeImage (e, version) {
      console.log(e)
      if (e) {
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload = (e) => {
          // let img = new Image()
          // img.src = e.target.result
          console.log(e)
          if (version === 'v1') {
            this.imageV1 = e.target.result
          }
          if (version === 'v2') {
            this.imageV2 = e.target.result
          }
        }
      }
    }
  }
}
